import { VStack, Text, Token, Flex, ButtonSkeleton, Spacer } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import {
  EpicOption,
  KpiInterface,
  KpiReviewCycle,
  KpiTargets,
  UpdateTypes,
} from '@src/interfaces/kpis'
import React, { useEffect, useMemo, useState } from 'react'
import { NewKPITargetFields } from '../../../KpiForm/common/NewKPITargetFields'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import { TargetCycleSelector } from '../../../KpiForm/common/TargetCycleSelector'
import { ReviewCycleCategory, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { JiraSearchWidget } from '@src/pages/OnboardingChecklist/Roadmaps/JiraSearch'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { ParentGoalField } from '../../common/ParentGoalField'
import { GoalsInterface } from '@src/interfaces/goals'
import { kpisRequestsNew } from '@src/api/kpis'
import InputsSkeleton from '@src/components/Skeletons/InputsSkeleton'
import Form from '@src/features/Form/Form'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { getTargetBoundaries } from '../Widgets/Targets/helpers'

const TargetFieldsByType = ({
  type,
  cycle,
}: {
  type?: UpdateTypes
  cycle?: ReviewCyclesInterface
}) => {
  const { values } = useLapeContext<KpiInterface>()

  const epics = useMemo(() => {
    return values.target_epics?.length
      ? values.target_epics[0].epics.reduce((acc, epicOption) => {
          if (epicOption.key) {
            return {
              ...acc,
              [epicOption.id]: {
                display_name: epicOption.name,
                epic_name: epicOption.name,
                epic_url: epicOption.url,
                id: epicOption.id,
                issue_type: 'Epic', // only epics could be selected
                key: epicOption.key,
              },
            }
          }
          return acc
        }, {})
      : undefined
  }, [])

  switch (type) {
    case UpdateTypes.roadmap:
      return (
        <JiraSearchWidget
          forceSelected={epics}
          onSelectionChange={selected => {
            if (!values.target_epics) {
              values.target_epics = []
            }

            const target = values.target_epics[0] || {}
            target.epics = Object.values(selected).map(
              epic =>
                ({
                  key: epic.key,
                  name: epic.epic_name,
                  owner: epic.owner,
                  url: epic.epic_url,
                  review_cycle: cycle as KpiReviewCycle,
                } as EpicOption),
            )
            values.target_epics[0] = target
          }}
        />
      )

    case UpdateTypes.sql:
    case UpdateTypes.manual:
    case UpdateTypes.looker:
      return (
        <NewKPITargetFields
          hideLooker
          codeEditorProps={{ responsive: true, height: 310 }}
        />
      )
    default:
      return null
  }
}

const AddGoalTargetForm = ({
  initialCycle,
  parent,
  onParentChanged,
  contentType,
  onPatched,
  hideCascaded,
  hideAggregated,
  owner,
  goalId,
  onTypeChanged,
}: {
  initialCycle?: ReviewCyclesInterface
  parent: GoalsInterface | null
  onParentChanged: (parent: GoalsInterface | null) => void
  contentType?: 'employees' | 'teams' | 'department'
  onPatched?: (kpi: KpiInterface) => void
  hideCascaded: boolean
  hideAggregated: boolean
  owner: EmployeeOptionInterface
  goalId: number
  onTypeChanged: (type?: UpdateTypes) => void
}) => {
  const { getEntityProps } = useOrgEntity()
  const form = useLapeContext<KpiInterface & { parent: GoalsInterface | null }>()
  const { values } = form
  const [cycle, setCycle] = useState<ReviewCyclesInterface | undefined>(initialCycle)

  useEffect(() => {
    if (cycle !== initialCycle) {
      setCycle(initialCycle)
    }
  }, [initialCycle])

  const updateTypesDictionary = {
    [UpdateTypes.aggregated]: {
      name: 'Agreggate from children level goals',
      description: 'The performance will aggregated based on children progress',
      visible: !hideAggregated,
    },
    [UpdateTypes.cascaded]: {
      name: 'Cascade from a higher level goal',
      description: 'The performance will be the same as the parent goal',
      visible: !hideCascaded,
    },
    [UpdateTypes.sql]: {
      name: 'Auto-update progress',
      description: 'Progress determined daily via a defined data connection',
      visible: true,
    },
    [UpdateTypes.looker]: {
      name: 'Looker based progress',
      description: 'Progress determined via Looker',
      visible: true,
    },
    [UpdateTypes.roadmap]: {
      name: 'Jira epics completion',
      description:
        'Progress will be determined by the completion of linked roadmap deliverables',
      visible: true,
    },
    [UpdateTypes.manual]: {
      name: 'Manual metric',
      description: 'The performance will be measured by manual inputs to the target',
      visible: true,
    },
  }

  const updateTypeOption = values.update_type
    ? { id: values.update_type, ...updateTypesDictionary[values.update_type] }
    : undefined

  const hasTarget =
    values.update_type === UpdateTypes.sql || values.update_type === UpdateTypes.manual

  const hasDeliverables = values.update_type === UpdateTypes.roadmap

  const target =
    (hasDeliverables
      ? values.target_epics && values.target_epics[0]
      : values.targets && values.targets[0]) || undefined

  useEffect(() => {
    values.is_inherited = values.update_type === UpdateTypes.cascaded

    if (hasTarget && !values.targets?.length) {
      values.targets = [{ is_top_down: false } as KpiTargets]
    }
  }, [values.update_type])

  useEffect(() => {
    const opposites = {
      review_cycle: 'employee_cycle',
      employee_cycle: 'review_cycle',
    }
    if (cycle && target) {
      const cycleKey = [ReviewCycleCategory.Probation, ReviewCycleCategory.PIP].includes(
        cycle?.category,
      )
        ? 'employee_cycle'
        : 'review_cycle'
      const fieldToClear = opposites[cycleKey] as 'employee_cycle' | 'review_cycle'

      if (target[cycleKey]?.id !== cycle.id) {
        target[cycleKey] = cycle
        target[fieldToClear] = undefined
      }
    }
  }, [cycle, target])

  const submit = async () => {
    const data = {
      ...getEntityProps(),
      ...getTargetBoundaries(values),
      ...values,
      owner: { id: owner?.id },
      goal: { id: goalId },
    }

    const response = await kpisRequestsNew.submit(data as unknown as KpiInterface, {})

    return response.data
  }

  const relativesBasedUpdateTypes = [UpdateTypes.aggregated, UpdateTypes.cascaded]

  const isTargetBasedUpdateType =
    values.update_type && !relativesBasedUpdateTypes.includes(values.update_type)

  return (
    <Flex
      aria-label="Add a target"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      gap="s-24"
    >
      <VStack space="s-16">
        {values.id ? null : (
          <LapeRadioSelectInput<IdAndName<UpdateTypes> & { description: string }>
            label="Target type"
            value={updateTypeOption}
            onChange={option => {
              values.update_type = option?.id
              onTypeChanged(option?.id)
            }}
            name="update_type"
            options={Object.entries(updateTypesDictionary)
              .filter(([_, { visible }]) => !!visible)
              .map(([id, value]) => ({
                value: { ...value, id: id as UpdateTypes },
                label: value.name,
              }))}
            searchable={false}
          >
            {option => (
              <VStack m="-s-2">
                <Text variant="primary">{option.label}</Text>
                <Text variant="caption" color={Token.color.greyTone50}>
                  {option.value.description}
                </Text>
              </VStack>
            )}
          </LapeRadioSelectInput>
        )}

        {values.update_type && values.update_type === UpdateTypes.cascaded ? (
          <ParentGoalField
            contentType={contentType}
            required
            value={parent}
            onChange={selected => {
              onParentChanged(selected)
            }}
          />
        ) : null}

        {values.update_type &&
          values.update_type !== UpdateTypes.cascaded &&
          values.update_type !== UpdateTypes.aggregated && (
            <>
              <TargetCycleSelector
                onSelect={setCycle}
                ownerId={values.owner?.id}
                isEmployee={values.is_employee}
                initialCycle={
                  (target?.review_cycle ||
                    target?.employee_cycle ||
                    initialCycle) as ReviewCyclesInterface
                }
              />
              <Text color={Token.color.greyTone50} variant="h6">
                Details
              </Text>
              {/* <Spacer height="s-16" /> */}
              <LapeNewInput name="name" label="Target name" required />
            </>
          )}
        <TargetFieldsByType type={values.update_type} cycle={cycle} />
      </VStack>
      {isTargetBasedUpdateType && (
        <Flex justifyContent="center" alignItems="center">
          <NewSaveButtonWithPopup<KpiInterface>
            onClick={values.id ? undefined : submit}
            width="350px"
            useValidator
            onAfterSubmit={result => onPatched && onPatched(result)}
          >
            {values.id ? 'Save target' : 'Add target'}
          </NewSaveButtonWithPopup>
        </Flex>
      )}
    </Flex>
  )
}

type AddGoalTargetFormProps = {
  initialValues: Partial<KpiInterface>
  initialCycle?: ReviewCyclesInterface
  parent: GoalsInterface | null
  onParentChanged: (parent: GoalsInterface | null) => void
  contentType?: 'teams' | 'employees' | 'department'
  onPatched: (kpi: KpiInterface) => void
  hideCascaded: boolean
  hideAggregated: boolean
  owner: EmployeeOptionInterface
  goalId: number
  onTypeChanged: (type?: UpdateTypes) => void
}

export default ({
  initialValues,
  initialCycle,
  parent,
  onParentChanged,
  contentType,
  onPatched,
  hideCascaded,
  hideAggregated,
  owner,
  goalId,
  onTypeChanged,
}: AddGoalTargetFormProps) => {
  return (
    <Form<KpiInterface>
      api={kpisRequestsNew}
      forceParams={{ id: initialValues.id ? String(initialValues.id) : undefined }}
      disableLocalStorageCaching
      ignoreLocationState
      loadingState={
        <>
          <VStack space="s-16">
            <InputsSkeleton />
            <Spacer height="s-16" />
            <InputsSkeleton />
          </VStack>
          <Flex justifyContent="center" alignItems="center">
            <ButtonSkeleton width="350px" />
          </Flex>
        </>
      }
    >
      <AddGoalTargetForm
        goalId={goalId}
        contentType={contentType}
        initialCycle={initialCycle}
        parent={parent}
        onParentChanged={onParentChanged}
        onPatched={onPatched}
        owner={owner}
        onTypeChanged={onTypeChanged}
        hideCascaded={hideCascaded}
        hideAggregated={hideAggregated}
      />
    </Form>
  )
}
